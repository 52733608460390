import moment from "moment";
import { serviceOrderType } from "../lib/types";

export const isAvailableAtCurrentTime = (data: any) => {
  if (data === null || data === undefined) {
    return false;
  }
  if (data.enable_service === false) {
    return false;
  }
  if (data.is_timebased === false) {
    return true;
  }
  if (data.start_hour === 0) {
    return true;
  }
  const startHour = moment().startOf("day").add(data.start_hour, "minutes");
  const endHour = moment().startOf("day").add(data.end_hour, "minutes");
  const currentTime = moment();
  const availableNow = currentTime.isBetween(startHour, endHour);
  return availableNow;
};

export const isWithinSubscriptionPeriod = (data: {
  is_manage_by_stripe: boolean;
  start_date: string;
  end_date: string;
}) => {
  if (data?.is_manage_by_stripe) {
    return true;
  }

  // Parsing dates
  const startDate = moment(data.start_date, "YYYY-MM-DDTHH:mm:ss.SSSZ", true); // Parse with strict mode
  let endDate = moment(
    data.end_date,
    "YYYY-MM-DDTHH:mm:ss.SSSZ",
    true
  ).subtract(1, "days");
  const currentDate = moment(); // Current local date-time

  console.log(startDate.toISOString(), "startDate validity");
  console.log(endDate.toISOString(), "endDate validity");
  console.log(currentDate.toISOString(), "currentDate validity");

  if (!startDate.isValid() || !endDate.isValid()) {
    console.error("Invalid start_date or end_date format");
    return false;
  }

  const isWithinPeriod = currentDate.isBetween(startDate, endDate, null, "[]");

  return isWithinPeriod;
};
