export const getStorageItem = (name: string) => {
  if (typeof window !== "undefined") {
    const data = localStorage.getItem(name);
    if (!data) {
      return null;
    }
    return data;
  }
};

export const getSessionItem = (name: string) => {
  if (typeof window !== "undefined") {
    const data = sessionStorage.getItem(name);
    if (!data) {
      return null;
    }
    return data;
  }
};
