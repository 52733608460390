import { apiInstance } from "../lib/url";
import { captureError } from "../lib/sentry";

export async function getStripeSubscription(comapnyInfoId : string) {
    try {
      const { data } = await apiInstance.get(
        `subscriptions/getStripeSubscription?company_id=${comapnyInfoId}`
      );
      
      return data;

    } catch (error) {
      console.warn(error);
      captureError(error);
    }

}


