// redux/alertSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

const initialState = {
  expired: false,
};

const expireSlice = createSlice({
  name: "expire",
  initialState,
  reducers: {
    setExpired: (state, action) => {
      state.expired = action.payload;
    },
  },
});

export const { setExpired } = expireSlice.actions;

export const getIsExpired = (state: RootState) => state.expire.expired;

export default expireSlice.reducer;
