import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { User } from "./../../types";
import { RootState } from "../store";

interface InitialStateType {
  customers: User[] | null;
  showCustomers: User[] | null;
  tableId: number | null;
}

const initialState: InitialStateType = {
  customers: null,
  showCustomers: null,
  tableId: null,
};

const CustomerSlice = createSlice({
  name: "User",
  initialState,
  reducers: {
    storeCustomers: (state, action) => {
      state.customers = action.payload;
    },
    storeShowCustomers: (state, action) => {
      state.showCustomers = action.payload;
    },
    editCustomer: (state, action) => {
      const { id } = action.payload;
      state.customers =
        state.customers?.map((i) =>
          i.id === id ? { id, ...action.payload } : i
        ) || null;
      state.showCustomers =
        state.showCustomers?.map((i) =>
          i.id === id ? { id, ...action.payload } : i
        ) || null;
    },
    insertTableId: (state, action: PayloadAction<number>) => {
      state.tableId = action.payload;
    },
    updateCustomerData: (state, action) => {
      const customers: any[] =
        state.customers?.map((customer: any) => {
          if (action?.payload?.id === customer?.id) {
            return { ...customer, company: action?.payload?.company };
          } else {
            return customer;
          }
        }) || [];
      state.customers = customers;
    },
  },
});
export const {
  storeCustomers,
  storeShowCustomers,
  editCustomer,
  insertTableId,
  updateCustomerData,
} = CustomerSlice.actions;

export const tableIdValue: any = (state: RootState) => state.customers.tableId;

export default CustomerSlice.reducer;
