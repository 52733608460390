import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface StaffServiceType {
  staffService: StaffService | null;
  staffServiceCategories: StaffServiceCategory[];
  selectedItem: StaffServiceItem | null;
}

const initialState: StaffServiceType = {
  staffService: null,
  staffServiceCategories: [],
  selectedItem: null,
};

const staffService = createSlice({
  name: "staffservice",
  initialState,
  reducers: {
    insertStaffService: (state, action) => {
      state.staffService = action.payload;
    },
    insertStaffServiceCategories: (state, action) => {
      state.staffServiceCategories = action.payload;
    },
    updateStaffServiceCategories: (state, action) => {
      state.staffServiceCategories = state.staffServiceCategories.map(
        (category) => {
          if (category.id === action.payload.id) {
            return action.payload;
          } else {
            return category;
          }
        }
      );
    },
    insertItem: (state, action) => {
      state.selectedItem = action.payload;
    },
  },
});

export const {
  insertStaffService,
  insertStaffServiceCategories,
  updateStaffServiceCategories,
  insertItem,
} = staffService.actions;

export const getStaffService = (state: RootState) =>
  state.staffService.staffService;

export const getStaffServiceCategories = (state: RootState) =>
  state.staffService.staffServiceCategories;

export const getSelectedItem = (state: RootState) =>
  state.staffService.selectedItem;

export default staffService.reducer;
