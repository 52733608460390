import { createSlice } from "@reduxjs/toolkit";

export interface SpaBookingType {
  spaService: SpaService | null;
  spaCategories: SpaCategory[];
  selectedCategory: SpaCategory | null;
  selectedItem: SpaCategoryItem | null;
  currentCategoryTabId: number;
  showBottomMenuTab: boolean;
  selectedBottomMenuItem: SpaCategoryItem | null;
  spaHistoryOrders: SpaOrder[];
}

const initialState: SpaBookingType = {
  spaService: null,
  spaCategories: [],
  selectedCategory: null,
  selectedItem: null,
  currentCategoryTabId: 0,
  showBottomMenuTab: false,
  selectedBottomMenuItem: null,
  spaHistoryOrders: [],
};

const spaBooking = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    insertSpaService: (state, action) => {
      state.spaService = action.payload;
    },
    insertSpaCategories: (state, action) => {
      state.spaCategories = action.payload;
    },

    addSpaCategories: (state, action) => {
      state.spaCategories = [...state.spaCategories, action.payload];
    },
    updateSpaCategories: (state, action) => {
      state.spaCategories = state.spaCategories.map((category) => {
        if (category.id == action.payload.id) {
          return action.payload;
        } else {
          return category;
        }
      });
    },

    insertSelectedCategory: (state, action) => {
      state.selectedCategory = action.payload;
    },
    insertSelectedItem: (state, action) => {
      state.selectedItem = action.payload;
    },
    insertCurrentCategoryTab: (state, action) => {
      state.currentCategoryTabId = action.payload;
    },

    insertShowBottomMenuTab: (state, action) => {
      state.showBottomMenuTab = action.payload;
    },
    insertSelectedBottomMenuItem: (state, action) => {
      state.selectedBottomMenuItem = action.payload;
    },
    insertSpaHistoryOrders: (state, action) => {
      state.spaHistoryOrders = action.payload;
    },
  },
});

export const {
  insertSpaService,
  insertSpaCategories,
  addSpaCategories,
  updateSpaCategories,
  insertSelectedCategory,
  insertSelectedItem,
  insertCurrentCategoryTab,
  insertSelectedBottomMenuItem,
  insertShowBottomMenuTab,
  insertSpaHistoryOrders,
} = spaBooking.actions;

export const getSpaServicesData: any = (state: any) =>
  state.spaBooking.spaService;

export const getSpaCategoriesData = (state: any) =>
  state.spaBooking.spaCategories;

export const getSelectedCategory = (state: any) =>
  state.spaBooking.selectedCategory;

export const getSelectedItem = (state: any) => state.spaBooking.selectedItem;

export const getCurrentCategoryTabId = (state: any) =>
  state.spaBooking.currentCategoryTabId;

export const getShowBottomMenuTab = (state: any) =>
  state.spaBooking.showBottomMenuTab;

export const getSelectedBottomMenuItem = (state: any) =>
  state.spaBooking.selectedBottomMenuItem;

export const getSpaHistoryOrders = (state: any) =>
  state.spaBooking.spaHistoryOrders;

export default spaBooking.reducer;
