import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface SideBarType {
  isOpen: boolean;
  socketEvent: any;
}

const initialState: SideBarType = {
  isOpen: false,
  socketEvent: "",
};

const sidebar = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    openSideBar: (state) => {
      state.isOpen = true;
    },
    closeSideBar: (state) => {
      state.isOpen = false;
    },
    insertSocketEvent: (state, action: PayloadAction<any>) => {
      state.socketEvent = action.payload;
    },
  },
});

export const { insertSocketEvent, openSideBar, closeSideBar } = sidebar.actions;

export const getSocketEvent: any = (state: any) => state.sidebar.socketEvent;

export default sidebar.reducer;
