import React, { createContext, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import moment from "moment";
import { Alert, Box, Dialog, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useRouter } from "next/router";
import { useDispatch } from "react-redux";
import { getIsExpired, setExpired } from "../redux/reducers/expireSlice";
import { UserIntialState } from "../redux/reducers/user";
import { companyInitialType } from "../redux/reducers/company";
import { loggedOutUser } from "../redux/reducers/user";
import { emptyCompany, emptyLocation } from "../redux/reducers/company";
import { signOut, useSession } from "next-auth/react";
import { SlLogout } from "react-icons/sl";
import { isWithinSubscriptionPeriod } from "../functions/hotel";
import { companyType } from "../lib/types";
import { baseInstance } from "../lib/baseUrl";
import { insertCompany, insertLocation } from "../redux/reducers/company";
import {
  insertCurrentStripePlanMetada,
  insertStripeSubscription,
  updateCompanySelectedLanguages,
  updateStripeCustomerId,
} from "../redux/reducers/company";
import { getStripeSubscription } from "../functions/getstripesubscription";
interface AlertContextProps {
  showAlert: () => void;
}
export const AlertContext = createContext<AlertContextProps>({
  showAlert: () => {},
});
export const AlertProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { t } = useTranslation();
  const { companyInfo, stripe_subscription } = useSelector(
    (state: RootState) => state.company
  ) as companyInitialType;

  const user = useSelector((state: RootState) => state.user) as UserIntialState;
  const isExpired = useSelector(getIsExpired);
  const [expiredDate, setExpiredDate] = useState<string>("");
  const { data: session, status }: any = useSession();
  const [isCanceled, setIsCanceled] = useState<boolean>(false);
  const route = useRouter();
  const { location_id } = route.query;
  const dispatch = useDispatch();

  const redirectToSubscriptions = async () => {
    route.push(
      `/backoffice/location/setting/subscriptions?location_id=${location_id}`
    );
  };

  useEffect(() => {
    if ((session?.user?.company || user) && status === "authenticated") {
      getCompanyInfo();
    }
  }, [session?.user?.company, user, status]);

  const getCompanyInfo = async () => {
    try {
      const { data }: { data: any } = await baseInstance.get(
        `company/getCompany?id=${session.user.company || user}`
      );

      if (data) {
        dispatch(insertCompany(data));
        if (
          !isWithinSubscriptionPeriod({
            is_manage_by_stripe: data?.is_manage_by_stripe,
            start_date: data?.start_date,
            end_date: data?.end_date,
          })
        ) {
          dispatch(setExpired(true));
          const endDate = moment(
            companyInfo?.end_date,
            "YYYY-MM-DD HH:mm:ss.SSS"
          );
          setExpiredDate(endDate.format("DD-MMM-YYYY"));
        } else {
          getSubScriptionData();
          dispatch(setExpired(false));
        }
      }
    } catch (err) {
      return;
    }
  };

  async function getSubScriptionData() {
    try {
      const {
        stripe_customer_id,
        subscription,
        stripe_metadata,
        selected_languages,
        stripe_subscription,
      } = await getStripeSubscription(companyInfo?.id);

      if (
        subscription ||
        stripe_customer_id ||
        selected_languages ||
        stripe_subscription
      ) {
        if (subscription && stripe_metadata) {
          dispatch(updateStripeCustomerId(stripe_customer_id));
          dispatch(insertStripeSubscription(subscription));
          dispatch(insertCurrentStripePlanMetada(stripe_metadata));
          dispatch(updateCompanySelectedLanguages(selected_languages));
          dispatch(insertStripeSubscription(stripe_subscription));
        } else if (stripe_subscription) {
          dispatch(insertStripeSubscription(stripe_subscription));
        }
      }
    } catch (err) {
      console.warn(err);
    }
  }

  useEffect(() => {
    if (companyInfo) handleShowAlert();
  }, [companyInfo, stripe_subscription]);

  const handleShowAlert = () => {
    if (companyInfo?.is_manage_by_stripe) {
      console.log("In Case ...", 1);
      if (Object.entries(stripe_subscription).length !== 0) {
        const trial_end = new Date(
          stripe_subscription.current_period_end * 1000
        );
        const stripeExpiredDate = moment(trial_end).format("DD-MMM-YYYY");
        setExpiredDate(stripeExpiredDate);
        if (
          stripe_subscription.cancel_at_period_end ||
          stripe_subscription.status == "past_due"
        ) {
          const cancelDate =
            stripe_subscription.status === "past_due"
              ? new Date(stripe_subscription.trial_end * 1000)
              : new Date(stripe_subscription.current_period_end * 1000);
          const currentDate = new Date();
          const expiredDate = moment(cancelDate).format("DD-MMM-YYYY");
          setExpiredDate(stripeExpiredDate);
          setIsCanceled(true);
          if (currentDate > cancelDate) {
            dispatch(setExpired(true));
          } else {
            dispatch(setExpired(false));
          }
          console.log("In Case ...", 2);
        } else {
          console.log("In Case ...", 3);
          const createdAt = new Date(companyInfo?.createdAt);
          let expiredDate = createdAt.getDate() + 30;
          const currentDate = new Date();
          createdAt.setDate(expiredDate);
          if (stripe_subscription?.current_period_end) {
            setExpiredDate(stripeExpiredDate);
          } else {
            setExpiredDate(moment(createdAt).format("DD-MMM-YYYY"));
          }
          if (
            !stripe_subscription.current_period_end &&
            !stripe_subscription.id
          ) {
            if (currentDate > createdAt) {
              dispatch(setExpired(true));
            } else {
              dispatch(setExpired(false));
            }
          } else if (currentDate > trial_end) {
            dispatch(setExpired(true));
          } else {
            dispatch(setExpired(false));
          }
        }
      } else {
        console.log("In Case ...", 4);
        setIsCanceled(false);
        const createdAt = new Date(companyInfo?.createdAt);
        let expiredDate = createdAt.getDate() + 30;
        const currentDate = new Date();
        createdAt.setDate(expiredDate);
        setExpiredDate(moment(createdAt).format("DD-MMM-YYYY"));
        console.log("In Case ...", 5);
        if (
          !stripe_subscription.current_period_end &&
          !stripe_subscription.id
        ) {
          console.log("In Case ...", currentDate, ">", createdAt);
          if (currentDate > createdAt) {
            dispatch(setExpired(true));
          } else {
            dispatch(setExpired(false));
          }
        }
      }
    } else {
      if (
        !isWithinSubscriptionPeriod({
          is_manage_by_stripe: companyInfo?.is_manage_by_stripe,
          start_date: companyInfo?.start_date,
          end_date: companyInfo?.end_date,
        })
      ) {
        dispatch(setExpired(true));
        const endDate = moment(
          companyInfo?.end_date,
          "YYYY-MM-DD HH:mm:ss.SSS"
        );
        setExpiredDate(endDate.format("DD-MMM-YYYY"));
      } else {
        dispatch(setExpired(false));
      }
    }
  };

  const shouldShowAlert = () => {
    const allowedRoutes = [
      "/backoffice/location/setting/subscriptions",
      "/checkout",
      "/signin",
      "/siginup",
    ];
    return allowedRoutes.includes(route.pathname);
  };

  const handleAlertClose = () => {
    return;
    // setIsExpired(false);
  };

  const handleSignOut = async () => {
    try {
      await dispatch(loggedOutUser());
      await dispatch(emptyCompany());
      await dispatch(emptyLocation());
      await signOut({ redirect: false, callbackUrl: "/signin" });
    } catch (error) {
      console.warn(error);
    }
  };

  if (shouldShowAlert()) {
    return <>{children}</>;
  }

  return (
    <AlertContext.Provider value={{ showAlert: handleShowAlert }}>
      {isExpired ? (
        <div className=" fixed w-screen h-screen overflow-hidden   left-0 top-0  opacity-[0.6] grayscale-[60%] z-[50px] "></div>
      ) : (
        <></>
      )}

      <>
        {children}
        {isExpired && (
          <Dialog
            sx={{ zIndex: 99999 }}
            open={isExpired}
            onClose={handleAlertClose}
          >
            <Alert severity="error">
              {companyInfo?.is_manage_by_stripe ? (
                <>
                  {isCanceled ? t("canceled-alert") : t("expired-alert")}{" "}
                  <span className=" font-bold">{expiredDate}</span>.
                </>
              ) : (
                <>
                  <span className=" ">
                    <span className=" text-[16px] ">
                      {" "}
                      Your subscription ended{" "}
                    </span>
                    <span className=" text-nowrap font-bold text-[16px]">
                      {expiredDate}
                    </span>
                  </span>
                  .
                </>
              )}
            </Alert>
            {companyInfo?.is_manage_by_strip ? (
              <Typography className="p-4">
                {user.isAdmin
                  ? t("choose-subscription-message")
                  : t("contact-your-admin")}
              </Typography>
            ) : (
              <Typography className="p-4  max-w-[350px] text-[16px] text-dark text-center z-[9999999]">
                <span>Please contact your admin or email us at </span>
                <a
                  href="mailto:support@innorder.com"
                  className=" underline cursor-pointer mr-[5px]"
                >
                  support@innorder.com
                </a>
                <span>for assistance.</span>
              </Typography>
            )}

            {companyInfo?.is_manage_by_stripe ? (
              <Box
                display={user.isAdmin ? "flex" : "none"}
                justifyContent="center"
              >
                <button
                  type="button"
                  onClick={redirectToSubscriptions}
                  className=" w-[200px] h-[40px] mx-[15px]   active:scale-95 transition-all duration-100   hover:bg-primaryOrange/90 btn-primary my-2"
                >
                  {t("ViewPlans")}
                </button>
              </Box>
            ) : (
              <></>
            )}

            <div className="w-full flex flex-row items-center justify-center pb-[15px] pt-[10px] ">
              <div
                onClick={handleSignOut}
                className={`  w-[120px]  h-[40px] active:scale-95   rounded-[5px]
                  bg-dark/80 hover:bg-dark/90 text-white  dark:bg-dark flex flex-row z-[99]
                  items-center text-[13px] justify-center  920:text-auto cursor-pointer   
                  transition-all duration-100   px-3 py-1    text-sm font-semibold  `}
              >
                <SlLogout className={` mr-[10px] text-[20px]  text-white `} />{" "}
                {t("signout")}
              </div>
            </div>
            {isExpired ? (
              <div className=" fixed w-screen h-screen overflow-hidden   left-0 top-0  opacity-[0.6] grayscale-[60%] z-[50px] "></div>
            ) : (
              <></>
            )}
          </Dialog>
        )}
      </>
      {isExpired ? (
        <div className=" fixed w-screen h-screen overflow-hidden   left-0 top-0  opacity-[0.6] grayscale-[60%] z-[50px] "></div>
      ) : (
        <></>
      )}
    </AlertContext.Provider>
  );
};
